.programs-header{
      margin-bottom: 40px;
}
.plans-container{
      margin-top: -4rem;
      padding: 0  2rem;
      display: flex;
      flex-direction: column;
      gap: 4rem;
      position: relative;
}
.plans{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3rem;
}
.plan{
      display: flex;
      flex-direction: column;
      background: var(--caloryCard);
      color: white;
      gap: 2rem;
      padding: 1.5rem;
      width: 15rem;
}
.plan:nth-child(2){
      background:var(--planCard);
      transform: scale(1.1);
}
.plan>svg{
      fill: var(--orange);
      width: 2rem;
      height: 2rem;
}
.plans:nth-child(2){
      font-size: 1rem;
      font-weight: bold;
}

.plans:nth-child(5){
      font-size: 0.8rem;  
}
.price{
      font-size: 3rem;
}
.features{
      display: flex;
      flex-direction: column;
      gap: 1rem;
}
.feature{
      display: flex;
      align-items: center;
      gap: 1rem;
}
.feature>img{
      width: 1rem;
}
.plans>:nth-child(2)>svg{
      fill: white;
}
.plans>:nth-child(2)>button{
      color: var(--orange);
}
.blur-p-1{
      width: 32rem;
      height: 23rem;
      margin-top: 170px;
      
}
.blur-p-2{
      width: 32rem;
      height: 23rem;
      right: 0;
      margin-top: 170px;
}
@media screen and (max-width:768px){
      .plans{
            flex-direction: column;
      }
      .plan:nth-child(2){
            transform: none;
      }
}




