.hero{
      display: flex;
      justify-content: space-between;
      

}
.left-h{
      padding: 2rem;
      padding-top: 1.5rem;
      flex: 3;
      display: flex;
      gap: 2rem;
      flex-direction: column;
}
.right-h{
      flex: 1;
      position: relative;
      background-color: var(--orange);
}
.the-best-ad{
      margin-top: 4rem;
      background-color: rgb(91, 96, 96);
      border-radius: 4rem;
      width: fit-content;
      padding: 20px 13px;
      text-transform: uppercase;
      color: #000;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}
.the-best-ad>span{
      z-index: 2;
}
.the-best-ad>div{
      position: absolute;
      background-color: var(--orange);
      width: 7rem;
      height: 80%;
      left: 8px;
      border-radius: 3rem;
      z-index: 1;
      animation: move 4s  ease infinite;
}
@keyframes move {
      0%{
            left: 7px;
            
      }
      50%{
            left :210px;
      }
      100%{
            left: 7px; 
      }
      
}
.hero-text{
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      text-transform: uppercase;
      font-size: 4.5rem;
      font-weight: bold;
      color: white;
      text-overflow: inherit;

}
.hero-text>div:nth-of-type(3){
      font-size: 1rem;
      font-weight: 200;
      text-transform: none;
      letter-spacing: 1px;
      width: 80%;

}
.figures{
      display: flex;
      gap: 2rem;
}
.figures>div{
      display: flex;
      flex-direction: column;
}
.figures>div>span:nth-of-type(1){
      color: white;
      font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
      color: var(--orange);
      text-transform: uppercase;
}
.hero-buttons{
      display: flex;
      gap: 1rem;
      font-weight: normal;
}
.hero-buttons>:nth-child(1){
      color: white;
      background-color: var(--orange);
      width: 8rem;
}
.hero-buttons>:nth-child(2){
      color: white;
      background-color: transparent;
      width: 8rem;
      border: 2px solid var(--orange);
}
.right-h>.btn{
      position: absolute;
      right: 3rem;
      top: 2rem;
      color: black;
}
.heart-rate{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: var(--darkGrey);
      width: fit-content;
      padding: 1rem;
      align-items: start;
      border-radius: 5px;
      position: absolute;
      right: 4rem;
      top: 7rem;
      animation: moveheart 4s  ease infinite;
}
@keyframes moveheart {
      0%{
            left: 160px;
            
      }
      50%{
            left :170px;
      }
      100%{
            left: 160px; 
      }
      
}
.heart-rate>img{
      width: 2rem;
}
.heart-rate>span:nth-child(2){
      color: var(--gray);
}
.heart-rate>span:nth-child(3){
      color:white;
      font-size: 1.5rem; 
}
.hero-image{
      position: absolute;
      top: 10rem;
      right: 8rem;
      width: 23rem;
}
.hero-image-back{
      position: absolute;
      top: 5rem;
      right: 18rem;

      z-index: -1;
      width: 15rem;
      animation: move-heart 6s ease infinite;
     
}
@keyframes move-heart {
      from{
            right: 10rem;
      }
      to{
            right: 18rem;
      }
      
}
.calories{
      display: flex;
      gap: 2rem;
      background-color: var(--caloryCard);
      border-radius: 5px;
      padding: 1rem;
      width: fit-content;
      position: absolute;
      top: 32rem;
      right: 28rem;
     /* animation: moveheart 4s  ease infinite;*/
      
}
.calories>img{
      width: 2rem;
     

}
.calories>div{
      display: flex;
      flex-direction: column;
      justify-content: space-between;

}
.calories>div>:nth-child(1){
      color: var(--gray);
}
.calories>div>:nth-child(2){
      color: white;
      font-size: 1.5rem;
}
.blur-h{
      width: 22rem;
      height: 30rem;
      left: 0;
}
@media screen and (max-width: 768px)
{
 .hero{
      flex-direction: column;
 }
 .blur-h{
      width: 14rem;
 }
 .the-best-ad{
      margin-top: 0;
      font-size: small;
      align-self: center;
      transform: scale(0.8);
 }
 @keyframes move {
      0%{
            left: 7px;
            
      }
      50%{
            left :130px;
      }
      100%{
            left: 7px; 
      }
}
 .hero-text{
      font-size: xx-large;
      align-items: center;
      justify-content: center;
 }
 .hero-text>div:nth-of-type(3){
      font-size: small;
      font-weight: 200;
      letter-spacing: 1px;
      text-align: center;
 }
 .hero-buttons{
      justify-content: center;
 }
 .figures>div>span:nth-of-type(1){
      font-size: large;
 }
 .figures>div>span:nth-of-type(2){
      font-size: small;
 }
 .right-h{
      position: relative;
      background: none;
}
.heart-rate{
      left: 1rem;
      top: 2rem;
      animation: none;
}
@keyframes move-heart {
      0%{
            left: 40px;
            
      }
      50%{
            left :100px;
      }
      100%{
            left: 40px; 
      }
}
.calories{
      position: relative;
      top: 5rem;
      left: 2rem;
}
.calories>img{
      width: 2rem;
}
.calories>div>:nth-child(2)
{
      color: white;
      font-size: 1rem;
}
.hero-image{
      position: relative;
      width: 15rem;
      left: 7rem;
      top: 4rem;
      align-self: center;
}
.hero-image-back{
      width: 15rem;
      left: 2rem;
      top: 0rem;
}

}



